import React from "react"
import ReactDOM from "react-dom/client"
import isBetween from "dayjs/plugin/isBetween"
import isLeapYear from "dayjs/plugin/isLeapYear"
import relativeTime from "dayjs/plugin/relativeTime"
import duration from "dayjs/plugin/duration"
import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { setScreenInfo } from "./utils/css"
import initECharts from "@/initECharts"
import dayjs from "dayjs"
import { workStore } from "./store"
import { killConsole } from "@/utils"
import { AliveScope } from "react-activation"

import "./intl"
import { EventEmitter } from "@/utils/event"
import Api from "@/server"

dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(isLeapYear)
dayjs.extend(relativeTime)

setScreenInfo()
initECharts()
workStore.initStore()
if (!workStore.token && !workStore.isLogin) {
    workStore.clearMultiWebsiteInfo()
}

window.addEventListener("resize", setScreenInfo)

let loopInterval: any = null

const loopGetNotification = async () => {
    if (loopInterval) {
        return
    }
    loopInterval = setInterval(async () => {
        const res = await Api.post(
            "/merchant/organizationalManage/memberNotification/count",
            {},
            {
                showError: false,
            },
        )
        workStore.setNotificationCount(res ?? 0)
        // 根据leo要求降低请求频次
    }, 5000)
}

const stopLoopGetNotification = () => {
    clearInterval(loopInterval)
    loopInterval = null
}

EventEmitter.on("loopGetNotification", loopGetNotification)
EventEmitter.on("stopLoopGetNotification", stopLoopGetNotification)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <AliveScope>
        <App />
    </AliveScope>,
)

reportWebVitals()
if (process.env.REACT_APP_ENV === "PROD") {
    killConsole()
} else {
    document.title = "后台-国内-测试"
}
