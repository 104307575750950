/**
 * 如果字符串以 http:// 或 https:// 开头，则保留前面的部分
 *
 * @param {string} inputString
 * @return {*}
 */
export const replaceDoubleSlashes = (inputString: string) => {
    if (inputString.startsWith("http://") || inputString.startsWith("https://")) {
        const protocolIndex = inputString.indexOf("//")
        const protocol = inputString.substring(0, protocolIndex + 2)
        const restOfString = inputString.substring(protocolIndex + 2)
        const replacedString = restOfString.split("//").join("/")
        return protocol + replacedString
    } else {
        // 否则，直接替换所有双斜杠为单斜杠
        return inputString.split("//").join("/")
    }
}

export const webpSupport = () => {
    const canvas = document.createElement("canvas")
    const webpSupport = canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0
    return webpSupport
}
