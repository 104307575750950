import local from "localforage"
import { get, set } from "../tools"

class PageControl {
    operationManagementMemberAnalysis: Record<string, any> = {}
    keys: string[] = ["operationManagementMemberAnalysis"]

    init = async () => {
        const keys = this.keys
        const promiseList = keys.map((key) => local.getItem(key))
        const results = await Promise.allSettled(promiseList)
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index]
            const result = results[index]
            if (result.status === "fulfilled" && result.value) {
                set(this, key, result.value)
            }
        }
    }

    destory = async () => {
        for (const key of this.keys) {
            set(this, key, {})
            local.removeItem(key)
        }
    }

    remove = async (key: string) => {
        set(this, key, {})
        await local.removeItem(key)
    }

    setOperationManagementMemberAnalysis = async (value: any) => {
        set(this, "operationManagementMemberAnalysis", value)
        await local.setItem("operationManagementMemberAnalysis", value)
    }
}

const pageControl = new PageControl()

export default pageControl
