import { getEnvConfig } from "@/env"
import { workStore } from "@/store"
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { get as lodashGet, set } from "@/utils/tools"
import PromiseQueue from "@/utils/promiseQueue"
import encrypt, { isOwnWebsite } from "@/utils/encrypt"
import { IApiExtra } from "./types"
import NotificationControl from "./NotificationControl"
import { get as toolsGet } from "@/utils/tools"
import _ from "lodash"
import { message } from "antd"

const openCrypt = getEnvConfig().open_crypt // 接口解密key

const axiosQueue = new PromiseQueue(8)

const notificationControl = new NotificationControl()

const timeoutValue = 2 * 60 * 1000

const whiteList = [
    "admin-prd-2wsx.cocokobe.com",
    "admin-sandbox-2wsx.cocokobe.com",
    "admin-saas-1qaz.cocokobe.com",
]
const isInWhiteList = !!whiteList.find((i: string) => window.location.hostname.endsWith(i))
axios.interceptors.request.use((config) => {
    try {
        const isOwn = isOwnWebsite(config)
        const backendService = toolsGet(config, "backendService", "") as string
        // 仅对 本站 内容处理
        if (isOwn) {
            const localLang = localStorage.getItem("lang") ?? "zh"

            config.headers["Content-Type"] = "application/json"
            config.headers["x-sys"] = backendService === "auth" ? "0" : "2"
            config.headers["C"] = workStore.companyCode
            config.headers["M"] = workStore.merchantCode
            if (!config.params) {
                config.params = {}
            }
            if (!config.params.lang) {
                // config.params.lang = localLang
            }
            // 仅对 post json格式的内容进行加密
            if (!(config.data instanceof FormData) && openCrypt && !isInWhiteList) {
                encrypt.encryptData(config)
                console.log("加密成功", config)
            }
        }
    } catch (e) {
        console.log("加密失败", e)
    }
    return {
        ...config,
    }
})

axios.interceptors.response.use(
    (response) => {
        if (openCrypt && !isInWhiteList) {
            try {
                const isOwn = isOwnWebsite(response.config)
                if (isOwn) {
                    let decryptData: any = response
                    if (response.config.responseType !== "blob") {
                        decryptData = encrypt.decryptData(response)
                        response.data = JSON.parse(decryptData)
                    }
                }
            } catch (e) {
                console.log("解密失败", e)
            }
        }
        return response
    },
    (error) => {
        const message = lodashGet(error, "message", "") as string
        if (error.response?.status >= 400) {
            notificationControl.addNotification({ msg: "服务器出错", type: "error" })
        } else if (
            ["timeout", "Network Error", "ECONNABORTED"].some((item) => message.includes(item))
        ) {
            notificationControl.addNotification({ msg: message, type: "error" })
        }
        return Promise.resolve(null)
    },
)

const interceptAxios = (params: AxiosRequestConfig): Promise<AxiosResponse<any, any>> => {
    return axiosQueue.createPool(() => axios({ ...params, timeout: timeoutValue })) as any
}

const verifyResp = (res: AxiosResponse<any, any>, apiExtra: IApiExtra = {}) => {
    const { toast = true } = apiExtra
    const code = lodashGet(res, "status", "200")
    if (`${code}` !== "200") {
        console.log("返回异常了", res)
        return false
    } else {
        const msg = lodashGet(res, "data.msg", "") as string
        const apiCode = lodashGet(res, "data.code", "") as string
        if (`${apiCode}` === "B0001") {
            workStore.logout()
        } else if (`${apiCode}` === "5009") {
            workStore.logout()
        } else if (msg !== "success" && msg !== "" && toast) {
            // Toast.show({
            //     content: msg,
            // })
            return false
        }
    }
    return true
}

const handleError = (response: AxiosResponse<any, any>, apiExtra: IApiExtra = {}): any => {
    const code = lodashGet(response, "data.code", null) as number | null
    const body = lodashGet(response, "data.body", null) as any
    const msg = lodashGet(response, "data.msg", "") as string
    const showError = lodashGet(apiExtra, "showError", true) as boolean

    // 错误处理
    if (code !== 200) {
        if (code === null) {
            // 后端没有正常返回报错
        } else if (code === 401) {
            // handle unauthorized error
        } else if (code === 403) {
            // handle forbidden error
        } else if (code === 404) {
            // handle not found error
        } else if (code === 422) {
            // handle validation error
        } else if (code >= 500) {
            // handle server error
        }
        // 单点登录
        if (code === 5011) {
            workStore.logout()
        }
        if (code === 5004) {
            notificationControl.addNotification({
                msg: msg || "TOKEN失效，请重新登录",
                type: "titps",
            })
            workStore.logout()
            return null
        }

        if (showError) {
            notificationControl.addNotification({ msg: msg || "请求失败", type: "titps" })
        }
        return null
    } else {
        return body ?? true
    }
}

const get = async (
    url: string,
    data: Record<string, string | number>,
    apiExtra: IApiExtra = {},
) => {
    try {
        const params = {
            currency: lodashGet(data, "currency", workStore.defaultCurrency),
            ...data,
        }
        if (data.currency === "") {
            _.unset(params, "currency")
        }
        const action = () =>
            interceptAxios({
                url,
                method: "get",
                params: {
                    currency: lodashGet(data, "currency", workStore.defaultCurrency),
                    ...data,
                },
                baseURL: getEnvConfig().ApiUrl,
                // headers: getHeader({ data, method: "get" }),
                headers: {
                    Authorization: workStore.token,
                },
            })
        let res: AxiosResponse<any, any>
        res = await action()
        verifyResp(res, apiExtra)
        if (!res && apiExtra.defaultData) {
            return apiExtra.defaultData
        }
        return res
    } catch (error) {
        return null
    }
}

const post = async (
    url: string,
    data: Record<string, any>,
    apiExtra: IApiExtra = {},
    timeout: number = timeoutValue,
) => {
    try {
        const params = {
            url,
            method: "post",
            timeout: timeout,
            params: {
                currency: lodashGet(data, "currency", workStore.defaultCurrency),
            },
            data:
                data instanceof Array
                    ? data
                    : {
                          ...data,
                          currency: lodashGet(data, "currency", workStore.defaultCurrency),
                      },
            baseURL: getEnvConfig().ApiUrl,
            headers: {
                Authorization: workStore.token,
            },
        }
        if (data.currency === "") {
            if (!(params.data instanceof Array)) {
                _.unset(params, "data.currency")
            }
            _.unset(params, "params.currency")
        }
        const res = await axios(params as any)
        verifyResp(res, apiExtra)
        if (res !== null) {
            const resData = handleError(res, apiExtra)
            if (!resData && apiExtra.defaultData) {
                return apiExtra.defaultData
            }
            return resData
        } else {
            return res
        }
    } catch (error) {
        console.log("error", error)
        return null
    }
}

const postAuth = async (url: string, data: Record<string, any>) => {
    try {
        const host = getEnvConfig().ApiHost
        const params = {
            url,
            method: "post",
            timeout: timeoutValue,
            data: data,
            baseURL: `${host}df-auth`,
            backendService: "auth",
        }
        const res = await axios(params as any)
        verifyResp(res, {})
        if (res !== null) {
            const resData = handleError(res, {})
            return resData
        } else {
            return res
        }
    } catch (error) {
        return null
    }
}

const downFile = async (url: string, data: Record<string, any>, method?: string) => {
    try {
        const params = {
            url,
            method: method || "post",
            timeout: 25000,
            params: {
                currency: lodashGet(data, "currency", workStore.defaultCurrency),
            },
            data: {
                ...data,
                currency: lodashGet(data, "currency", workStore.defaultCurrency),
            },
            responseType: "blob",
        }
        if (data.currency === "") {
            _.unset(params, "data.currency")
            _.unset(params, "params.currency")
        }
        if (!url.startsWith("http")) {
            set(params, "baseURL", getEnvConfig().ApiUrl)
            set(params, "headers.Authorization", workStore.token)
            set(params, "headers.C", workStore.companyCode)
            set(params, "headers.M", workStore.merchantCode)
        }
        const res = await axios(params as any)

        const status = `${lodashGet(res, "status", "200")}`
        const fileName = lodashGet(res, "headers.content-disposition", "")
            .replace("attachment; ", "")
            .replace("filename=", "")
        if (
            res.data instanceof Blob &&
            status === "200" &&
            ["application/octet-stream", "text/xml"].includes(res.data.type)
        ) {
            return { file: res.data, fileName: decodeURIComponent(fileName) }
        } else {
            const fileReader = new FileReader()
            fileReader.readAsText(res.data, "utf-8")
            fileReader.onload = () => {
                const result = JSON.parse(fileReader.result as string)
                message.error(result.msg)
            }
        }
    } catch (error) {
        return null
    }
}

const moduleExport = { get, post, downFile, postAuth }

export default moduleExport
