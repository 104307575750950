import React, { useEffect, useState } from "react"
import { List, notification } from "antd"
import "./index.scss"

interface Props {
    message: string
    notNetworkProblem?: boolean
}

export default React.memo<Props>((props) => {
    const { message } = props
    const errorList = [
        "服务器异常，请联系后端同学",
        "运维环境异常，请联系运维同学",
    ]
    if (!props.notNetworkProblem) {
        errorList.unshift("本地网络不稳定")
    }
    return (
        <div className="error-message">
            <div className="error-header">网络错误,可能存在以下问题</div>
            <div className="error-detail">{message}</div>
            <List dataSource={errorList} renderItem={(item, index) =>
                <List.Item key={index}>{`${index + 1}、${item}`}</List.Item>} />
        </div>
    )
})
