export default {
    // 通用组件
    通用组件: {
        提示: "提示",
        确定: "确定",
        取消: "取消",
        保存: "保存",
        删除: "删除",
        退出: "退出",
        退出登录: "退出登录",
        退出登录提示: "确定要退出登录吗？",
        退出登录成功: "退出登录成功",
        退出登录失败: "退出登录失败",
        网络错误: "网络错误",
        服务器错误: "服务器错误",
        网络超时: "网络超时",
    },
    // 登录页面
    登录页面: {
        登录: "登录",
        登录成功: "登录成功",
        登录失败: "登录失败",
        用户名: "用户名",
        密码: "密码",
        用户名提示: "请输入用户名",
        密码提示: "请输入密码",
        用户名错误: "用户名错误",
        密码错误: "密码错误",
        用户名或密码错误: "用户名或密码错误",
        用户名或密码不能为空: "用户名或密码不能为空",
        请先登录: "请先登录",
    },
}
