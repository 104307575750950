import { notification } from "antd"
import ErrorNotification from "./ErrorNotification"
import { debounce, uniqBy } from "lodash"

class NotificationControl {
    notifications: Array<{ msg: string, type: 'titps' | 'error' }> = []

    addNotification = (info: { msg: string, type: 'titps' | 'error' }) => {
        this.notifications = uniqBy([...this.notifications, info], 'msg')
        this.next()
    }

    next = debounce(() => {
        if (this.notifications.length !== 0) {
            const first = this.notifications[0]
            const message = first as { msg: string, type: 'titps' | 'error' }
            if (message.type === 'titps') {
                notification.error({
                    className: 'error-notification',
                    message: `${message.msg}`,
                    duration: 5,
                })
            } else {
                notification.error({
                    className: 'error-notification',
                    message: <ErrorNotification message={message.msg} />,
                    duration: 5,
                })
            }
            this.notifications.shift()
        }
    }, 1500, { leading: true, trailing: true })
}

export default NotificationControl