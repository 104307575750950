import dataHandle from "./encrypt"
import { getEnvConfig } from "@/env"

// 数据加密
const key = getEnvConfig().crypt_key // 接口解密key
const authKey = getEnvConfig().crypt_key_auth // 接口解密key
const openCrypt = getEnvConfig().open_crypt // 接口解密key

export const isOwnWebsite = (config) => {
    return !!config.baseURL
    // const maps = ["admin-prd.cocokobe.com", "admin.cocokobe.com"]
    // const host = url.match(/^(https?:\/\/)([^\/]+)/)[2]
    // return maps.includes(host)
}

/**
 * @param {} config 请求设置
 */
const encryptData = (config) => {
    const encryptKey = config.backendService === "auth" ? authKey : key
    // 请求参数加密
    const encryptData = dataHandle.encrypt(config.data || {}, encryptKey)
    // 生成加密签名
    const nonce = dataHandle.createNonce()
    const timestamp = dataHandle.createTimestamp()
    const sign = dataHandle.createSign(encryptData, nonce, timestamp, encryptKey)

    config.data = encryptData
    // 将加密相关参数同步请求头
    const token = JSON.stringify({
        encrypted: openCrypt, // 是否启用参数与响应加密
        nonce,
        timestamp,
        sign,
        gzipped: false, // 是否启用gzip
    })
    config.headers.token = dataHandle.encrypt(token, encryptKey)
    return config
}

const decryptData = (response) => {
    const data = response.data
    const config = response.config
    const encryptKey = config.backendService === "auth" ? authKey : key
    return dataHandle.decrypt(data, encryptKey)
}

const decryptDomainData = (data, key) => {
    return dataHandle.decrypt(data, key)
}

export default { encryptData, decryptData, decryptDomainData }
