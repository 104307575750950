import dayjs, { Dayjs } from "dayjs"
import { get, isEmpty } from "./tools"

/**
 * 获得两个两个时间间隔
 *
 * @param {(number | string | null | Dayjs)} time
 * @param {(number | string | Dayjs | null)} time2
 * @return {*}
 */
export const getTimeDuration = (
    time: number | string | null | Dayjs,
    time2: number | string | Dayjs | null,
) => {
    if (isEmpty(time)) {
        return ""
    }
    const now = dayjs(time2).valueOf()
    const duration = dayjs.duration(now - dayjs(time).valueOf())
    const { years, months, days, hours, minutes, seconds } = get(duration, "$d", {}) as Record<
        string,
        number
    >
    const result = [
        years > 0 ? `${years}年` : "",
        months > 0 ? `${months}个月` : "",
        days > 0 ? `${days}天` : "",
        hours > 0 ? `${hours}小时` : "",
    ]
    return `${result.join("")}${minutes}分钟${seconds}秒`
}

export const getTimeDurationByDay = (
    time: number | string | null | Dayjs,
    time2: number | string | Dayjs | null,
) => {
    if (isEmpty(time)) {
        return ""
    }
    const now = dayjs(time2)
    const duration = dayjs(time).diff(now, "day")
    return `${Math.abs(duration)}天`
}

/**
 * 得到距离现在的时间
 *
 * @param {(number | string)} time
 * @param {("second" | "day")} [type="second"]
 * @return {*}
 */
export const getDurationFromNow = (time: number | string | null, type = "second") => {
    if (type === "day") {
        return getTimeDurationByDay(time, dayjs())
    }
    return getTimeDuration(time, dayjs())
}

/**
 * 格式化时间戳，允许设置空值时的返回
 */
export const formatTime = (time: number, format = "YYYY-MM-DD HH:mm:ss", emptyValue = "/") => {
    if (isEmpty(time)) {
        return emptyValue
    }
    return dayjs(time).format(format)
}
