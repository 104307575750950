import { getWindowHeight, getWindowWidth } from "."

export const setScreenInfo = () => {
    const width = getWindowWidth()
    const height = getWindowHeight()
    document.documentElement.style.setProperty("--safe-bottom-height", `${8}px`)
    document.documentElement.style.setProperty("--screen-width", `${width}px`)
    document.documentElement.style.setProperty("--screen-height", `${height}px`)
}

export const getSafeBottom = () => {
    const result = getComputedStyle(document.documentElement)
        .getPropertyValue("--safe-bottom-height")
        .replace("px", "")
    return Number(result)
}

export const getStyleVarCss = (key: string, value?: string | number) => {
    if (!value) {
        return {}
    }
    const str = typeof value === "number" ? `${value}px` : value
    return {
        [`${key}`]: str,
    } as any
}

export const getStyleVarCssArr = (arr: Array<{ key: string; value?: string | number }>) => {
    let result: Record<string, string> = {}
    arr.forEach((item) => {
        result = {
            ...result,
            ...getStyleVarCss(item.key, item.value),
        }
    })
    return result
}