import { set } from "@/utils/tools"

export enum 环境 {
    开发 = "DEV",
    测试 = "TEST",
    预发 = "RC",
    生产 = "PROD",
}

enum 密钥 {
    开发 = "wl3afmNKZux8f0NV",
    测试 = "wl3afmNKZux8f0NV",
    预发 = "wl3afmNKZux8f0NV",
    生产 = "AX59OWdK8ijn09gE",
}

enum 密钥Auth {
    开发 = "9PoilulXan2ZF5rW",
    测试 = "9PoilulXan2ZF5rW",
    预发 = "9PoilulXan2ZF5rW",
    生产 = "zrrNU71yKik9jk1N",
}

export enum 环境HOST {
    // 开发 = "http://192.168.31.170:8016/df-manage",
    开发 = "https://gateway.cocokobe.com/",
    测试 = "https://gw-sandbox.cocokobe.com/",
    预发 = "https://gw-sandbox.cocokobe.com/",
    生产 = "https://gw.apia3xjg.com/",
}

interface envKeys {
    crypt_key: 密钥
    crypt_key_auth: 密钥Auth
    open_crypt: boolean
    ApiHost: string
    ApiUrl: string
}

type Env = Record<环境, envKeys>
export const GLOBAL_ENV: Env = {
    [环境.开发]: {
        crypt_key: 密钥.开发,
        crypt_key_auth: 密钥Auth.开发,
        open_crypt: false,
        ApiHost: `${环境HOST.开发}`,
        ApiUrl: `${环境HOST.开发}df-manage`,
    },
    [环境.测试]: {
        crypt_key: 密钥.测试,
        crypt_key_auth: 密钥Auth.测试,
        open_crypt: false,
        ApiHost: `${环境HOST.测试}`,
        ApiUrl: `${环境HOST.测试}df-manage`,
    },
    [环境.预发]: {
        crypt_key: 密钥.预发,
        crypt_key_auth: 密钥Auth.预发,
        open_crypt: false,
        ApiHost: `${环境HOST.预发}`,
        ApiUrl: `${环境HOST.预发}df-manage`,
    },
    [环境.生产]: {
        crypt_key: 密钥.生产,
        crypt_key_auth: 密钥Auth.生产,
        open_crypt: true,
        ApiHost: `${环境HOST.生产}`,
        ApiUrl: `${环境HOST.生产}df-manage`,
    },
}

export const getEnvConfig = () => {
    let 当前环境 = process.env.REACT_APP_ENV as 环境
    let envName = 当前环境 || 环境.开发

    const envConfig = GLOBAL_ENV[envName]
    if (
        window.location.host.includes("admin-saas.cocokobe.com") ||
        window.location.host.includes("admin-saas-1qaz.cocokobe.com")
    ) {
        set(envConfig, "ApiHost", "https://saasgw.k1h5gw.com/")
        set(envConfig, "ApiUrl", "https://saasgw.k1h5gw.com/df-manage")
    }
    return envConfig
}
