import intl from 'react-intl-universal'
import { EventEmitter } from "@/utils/event"

//全局初始化
const locales = {
    "en": require('./locales/en.js').default,
    "zh": require('./locales/zh.js').default,
}
const localLang = localStorage.getItem("lang") ?? 'zh'
intl.init({ currentLocale: localLang, locales })

function setLocale(lang: string) {
    localStorage.setItem("lang", lang)
    intl.init({ currentLocale: lang, locales })
    window.location.reload()
}

EventEmitter.on('SetLocale', setLocale)
