const globalEnumPermData = {
    语言类型: {
        en: "英语",
        pt: "葡萄牙语",
        es: "西班牙语",
    },
    热门游戏_类别: {
        子游戏: false,
        平台: true,
    },
    权限枚举值: {
        数据看板: `platformDashboard`,
        数据看板__页面查看: `platformDashboardPerms`,
        会员管理: `memberManage`,
        会员管理__会员列表: `memberList`,
        会员管理__会员列表__页面查看: `memberQueryPerms`,
        会员管理__会员列表__导出: `memberExportPerms`,
        会员管理__会员详情: `memberDetail`,
        会员管理__会员详情__基础信息: `memberInfoTab`,
        会员管理__会员详情__基础信息__页签查看: `memberInfoQueryPerms`,
        会员管理__会员详情__基础信息__会员分层: `memberInfoLevelPerms`,
        会员管理__会员详情__基础信息__会员状态: `memberInfoStatusPerms`,
        会员管理__会员详情__基础信息__编辑资料: `memberInfoEditPerms`,
        会员管理__会员详情__基础信息__修改登录密码: `memberInfoLoginPwdPerms`,
        会员管理__会员详情__基础信息__修改保险箱密码: `memberInfoSafePwdPerms`,
        会员管理__会员详情__基础信息__重置支付密码: `memberInfoResetPwdPerms`,
        会员管理__会员详情__基础信息__新增备注: `memberRemarkEditPerms`,
        会员管理__会员详情__账户信息: `memberBalanceTab`,
        会员管理__会员详情__账户信息__页签查看: `memberBalanceQueryPerms`,
        会员管理__会员详情__账户信息__场馆回收: `memberBalanceRecyclePerms`,
        会员管理__会员详情__账户信息__保险箱回收: `memberBalanceSafeRecyclePerms`,
        会员管理__会员详情__财务信息: `memberFinancialTab`,
        会员管理__会员详情__财务信息__页签查看: `memberFinancialQueryPerms`,
        会员管理__会员详情__卡号信息: `memberBankWalletTab`,
        会员管理__会员详情__卡号信息__页签查看: `memberBankWalletQueryPerms`,
        会员管理__会员详情__卡号信息__删除: `memberBankWalletDelPerms`,
        会员管理__现金系统: `cashSystem`,
        会员管理__现金系统__页面查看: `cashSystemQueryPerms`,
        会员管理__现金系统__导出: `cashSystemExportPerms`,
        会员管理__层级设置: `userLevelSetting`,
        会员管理__层级设置__页面查看: `userLevelQueryPerms`,
        会员管理__层级设置__添加层级: `userLevelAddPerms`,
        会员管理__层级设置__编辑: `userLevelEditPerms`,
        会员管理__层级设置__状态开关: `userLevelStatusPerms`,
        会员管理__VIP等级设置: `vipLevelSetting`,
        会员管理__VIP等级设置__页面查看: `vipLevelQueryPerms`,
        会员管理__VIP等级设置__编辑: `vipLevelEditPerms`,
        会员管理__VIP等级设置__新增VIP等级: `vipLevelAddPerms`,
        会员管理__异常流水: `gameLogError`,
        会员管理__异常流水__页面查看: `gameLogErrorQueryPerms`,
        会员管理__异常流水__强制回收: `gameLogErrorTransferPerms`,
        游戏管理: `gameManage`,
        游戏管理__平台注单: `gameOrderList`,
        游戏管理__平台注单__平台注单: `gameOrderListTab`,
        游戏管理__平台注单__平台注单__页面查看: `gameOrderListQueryPerms`,
        // 游戏管理__平台注单__人工拉取: `gameOrderListPullTab`,
        // 游戏管理__平台注单__人工拉取__页面查看: `gameOrderListPullQueryPerms`,
        // 游戏管理__平台注单__人工拉取__人工拉取注单: `gameOrderListPullPerms`,
        游戏管理__游戏管理: `gameConfigSetting`,
        游戏管理__游戏管理__分类设置: `gameCategoryTab`,
        游戏管理__游戏管理__分类设置__页签查看: `gameCategoryQueryPerms`,
        游戏管理__游戏管理__分类设置__编辑: `gameCategoryEditPerms`,
        游戏管理__游戏管理__分类设置__状态开关: `gameCategoryStatusPerms`,
        游戏管理__游戏管理__平台管理: `gamePlatformTab`,
        游戏管理__游戏管理__平台管理__页签查看: `gamePlatformQueryPerms`,
        游戏管理__游戏管理__平台管理__编辑: `gamePlatformEditPerms`,
        游戏管理__游戏管理__平台管理__维护开关: `gamePlatformMaintenancePerms`,
        游戏管理__游戏管理__平台管理__热门开关: `gamePlatformHotPerms`,
        游戏管理__游戏管理__平台管理__状态开关: `gamePlatformStatusPerms`,
        游戏管理__游戏管理__子游戏管理: `gameConfigTab`,
        游戏管理__游戏管理__子游戏管理__页签查看: `gameConfigQueryPerms`,
        游戏管理__游戏管理__子游戏管理__添加: `gameConfigAddPerms`,
        游戏管理__游戏管理__子游戏管理__编辑: `gameConfigEditPerms`,
        游戏管理__游戏管理__子游戏管理__维护开关: `gameConfigMaintenancePerms`,
        游戏管理__游戏管理__子游戏管理__热门开关: `gameConfigHotPerms`,
        游戏管理__游戏管理__子游戏管理__启用禁用: `gameConfigStatusPerms`,
        游戏管理__游戏管理__热门游戏: `gameConfigHotTab`,
        游戏管理__游戏管理__热门游戏__页签查看: `gameConfigHotQueryPerms`,
        游戏管理__游戏管理__热门游戏__编辑: `gameConfigHotEditPerms`,
        游戏管理__游戏管理__热门游戏__移除: `gameConfigHotDeletePerms`,
        游戏管理__游戏管理__类型管理: 'gameCategoryTab',
        // 未使用
        游戏管理__游戏管理__类型管理__页签查看: 'gameCategoryQueryPerms',
        游戏管理__游戏管理__类型管理__编辑: 'gameCategoryEditPerms',
        游戏管理__游戏管理__类型管理__状态开关: 'gameCategoryStatusPerms',
        运营管理: `operationsManage`,
        运营管理__轮播管理: `carouselSetting`,
        运营管理__轮播管理__页面查看: `carouselQueryPerms`,
        运营管理__轮播管理__添加轮播图片: `carouselAddPerms`,
        运营管理__轮播管理__编辑: `carouselEditPerms`,
        运营管理__轮播管理__状态开关: `carouselStatusPerms`,
        运营管理__轮播管理__批量删除: `carouselDelPerms`,
        运营管理__消息管理: `messageSetting`,
        运营管理__消息管理__系统公告: `notificationSetting`,
        运营管理__消息管理__系统公告__页签查看: `notificationQueryPerms`,
        运营管理__消息管理__系统公告__发布公告: `notificationAddPerms`,
        运营管理__消息管理__系统公告__置顶: `updateSysAnnouncementSequence`,
        运营管理__消息管理__系统公告__状态开关: `notificationStautsPerms`,
        运营管理__消息管理__系统公告__编辑: `notificationEditPerms`,
        运营管理__消息管理__系统公告__删除: `notificationDelAction`,
        运营管理__消息管理__系统公告__批量删除: `notificationDelPerms`,
        运营管理__消息管理__站内消息: `siteMessageSetting`,
        运营管理__消息管理__站内消息__页签查看: `siteMessageQueryPerms`,
        运营管理__消息管理__站内消息__发布站内信息: `siteMessageAddPerms`,
        运营管理__消息管理__站内消息__编辑: `siteMessageEditPerms`,
        运营管理__消息管理__站内消息__删除: `siteMessageDelPerms`,
        运营管理__消息管理__游戏公告: `announcementSetting`,
        运营管理__消息管理__游戏公告__页签查看: `announcementQueryPerms`,
        运营管理__消息管理__游戏公告__发布游戏公告: `announcementAddPerms`,
        运营管理__消息管理__游戏公告__编辑: `announcementEditPerms`,
        运营管理__消息管理__游戏公告__删除: `announcementDelPerms`,
        运营管理__站点配置: `webSetting`,
        运营管理__站点配置__网站信息: `webConfigTab`,
        运营管理__站点配置__网站信息__页签查看: `webConfigQueryPerms`,
        运营管理__站点配置__网站信息__保存: `webConfigAddPerms`,
        运营管理__站点配置__网站信息__上传包: `appUploadPerms`,
        运营管理__站点配置__网站信息__下载包: `appDownloadPerms`,
        运营管理__站点配置__注册设置: `registerTab`,
        运营管理__站点配置__注册设置__页签查看: `registerQueryPerms`,
        运营管理__站点配置__注册设置__保存: `registerEditPerms`,
        运营管理__站点配置__登录设置: `loginTab`,
        运营管理__站点配置__登录设置__页签查看: `loginQueryPerms`,
        运营管理__站点配置__登录设置__保存: `loginEditPerms`,

        // 运营管理__站点配置__基础资源配置: `basicResourceTab`,
        // 运营管理__站点配置__基础资源配置__保存: `basicResourceEditPerms`,
        //
        // 运营管理__站点配置__APP包管理: `appPackageTab`,
        // 运营管理__站点配置__APP包管理__保存: `appPackageEditPerms`,

        运营管理__站点配置__汇率设置: `exchangeRateTab`,
        运营管理__站点配置__汇率设置__页签查看: `exchangeRateQueryPerms`,
        运营管理__站点配置__汇率设置__保存: `exchangeRateEditPerms`,
        运营管理__站点配置__绑卡提现配置: `withdrawCardTab`,
        运营管理__站点配置__绑卡提现配置__页签查看: `withdrawCardQueryPerms`,
        运营管理__站点配置__绑卡提现配置__保存: `withdrawCardEditPerms`,
        运营管理__站点配置__客服设置: `customerServiceTab`,
        运营管理__站点配置__客服设置__页签查看: `customerServiceQueryPerms`,
        运营管理__站点配置__客服设置__保存: `customerServiceEditPerms`,
        运营管理__站点配置__常见问题: `questionTab`,
        运营管理__站点配置__常见问题__页签查看: `questionQueryPerms`,
        运营管理__站点配置__常见问题__添加问题: `questionAddPerms`,
        运营管理__站点配置__常见问题__编辑: `questionEditPerms`,
        运营管理__站点配置__常见问题__删除: `questionDelPerms`,
        运营管理__会员分析: `memberAnalysis`,
        运营管理__会员分析__出入款统计: `financeTab`,
        运营管理__会员分析__出入款统计__页签查看: `financeQueryPerms`,
        运营管理__会员分析__出入款统计__导出: `financeExportPerms`,
        运营管理__会员分析__优惠: `discountTab`,
        运营管理__会员分析__优惠__页签查看: `discountQueryPerms`,
        运营管理__会员分析__优惠__导出: `discountExportPerms`,
        运营管理__会员分析__下注分析: `betAnalysisTab`,
        运营管理__会员分析__下注分析__页签查看: `betAnalysisQueryPerms`,
        运营管理__会员分析__下注分析__导出: `betAnalysisExportPerms`,
        运营管理__会员分析__投注人数: `betUserTab`,
        运营管理__会员分析__投注人数__页签查看: `betUserQueryPerms`,
        运营管理__会员分析__投注人数__导出: `betUserExportPerms`,
        运营管理__版本控制: `versionControl`,
        运营管理__版本控制__App更新: `appUpdateTab`,
        运营管理__版本控制__App更新__页签查看: `appUpdateQueryPerms`,
        运营管理__版本控制__App更新__新增版本: `appUpdateAddPerms`,
        运营管理__版本控制__App更新__状态开关: `appUpdateStatusPerms`,
        运营管理__版本控制__App更新__编辑: `appUpdateEditPerms`,
        运营管理__版本控制__离线包管理: `offlinePackageTab`,
        运营管理__版本控制__离线包管理__页签查看: `offlinePackageQueryPerms`,
        运营管理__版本控制__离线包管理__新增离线包地址: `offlinePackageAddPerms`,
        运营管理__版本控制__离线包管理__删除: `offlinePackageDelPerms`,
        运营管理__版本控制__离线包管理__状态开关: `offlinePackageStatusPerms`,
        优惠中心: `bonusActivityManage`,
        优惠中心__活动中心: `activityCenter`,
        优惠中心__活动中心__页签管理: `activityTagTab`,
        优惠中心__活动中心__页签管理__页签查看: `activityTagQueryPerms`,
        优惠中心__活动中心__页签管理__新增页签: `activityTagAddPerms`,
        优惠中心__活动中心__页签管理__删除: `activityTagDelPerms`,
        优惠中心__活动中心__页签管理__编辑: `activityTagEditPerms`,
        优惠中心__活动中心__页签管理__状态开关: `activityTagStatusPerms`,
        优惠中心__活动中心__活动列表: `activityListTab`,
        优惠中心__活动中心__活动列表__活动查看: `activityQueryPerms`,
        优惠中心__活动中心__活动列表__新增普通活动: `activityAddPerms`,
        优惠中心__活动中心__活动列表__编辑: `activityEditPerms`,
        优惠中心__活动中心__活动列表__删除: `activityDelPerms`,
        优惠中心__活动中心__活动列表__状态开关: `activityStatusPerms`,
        优惠中心__任务管理: `taskSetting`,
        优惠中心__任务管理__页签管理: `taskTagManagementTab`,
        优惠中心__任务管理__页签管理__页签查看: `taskTagQueryPerms`,
        优惠中心__任务管理__页签管理__状态开关: `taskTagStatusPerms`,
        优惠中心__任务管理__页签管理__删除: `taskTagDelPerms`,
        优惠中心__任务管理__页签管理__编辑: `taskTagEditPerms`,
        优惠中心__任务管理__页签管理__新增页签: `taskTagAddPerms`,
        优惠中心__任务管理__任务管理: `taskSettingTab`,
        优惠中心__任务管理__任务管理__任务查看: `taskQueryPerms`,
        优惠中心__任务管理__任务管理__任务添加: `taskSettingAddPerms`,
        优惠中心__任务管理__任务管理__状态开关: `taskStatusPerms`,
        优惠中心__任务管理__任务管理__编辑: `taskEditPerms`,
        优惠中心__任务管理__任务管理__删除: `taskDelPerms`,
        优惠中心__任务管理__领取记录: `taskLogTab`,
        优惠中心__任务管理__领取记录__页签查看: `taskLogQueryPerms`,
        优惠中心__任务管理__领取记录__导出: `taskLogExportPerms`,
        优惠中心__预送彩金: `preBonus`,
        优惠中心__预送彩金__赠送彩金: `preBonusTab`,
        优惠中心__预送彩金__赠送彩金__页签查看: `preBonusQueryPerms`,
        优惠中心__预送彩金__赠送彩金__添加数据: `preBonusAddPerms`,
        优惠中心__预送彩金__赠送彩金__编辑: `preBonusEditPerms`,
        优惠中心__预送彩金__赠送彩金__发送: `preBonusDispatchPerms`,
        优惠中心__预送彩金__赠送记录: `preBonusLogTab`,
        优惠中心__预送彩金__赠送记录__页签查看: `preBonusLogQueryPerms`,
        优惠中心__预送彩金__赠送记录__导出: `preBonusLogExportPerms`,
        优惠中心__红包活动: `redPackageActivity`,
        优惠中心__红包活动__页面查看: `redPackageQueryPerms`,
        优惠中心__红包活动__发送包: `redPackageBonusPerms`,
        优惠中心__红包活动__关闭: `redPackageDelPerms`,
        优惠中心__红包活动__编辑: `redPackageEditPerms`,
        优惠中心__红包活动__补领: `redPackageReplacePerms`,
        优惠中心__红包活动__导出红包: `redPackageExportPerms`,
        优惠中心__签到活动: `signActivity`,
        优惠中心__签到活动__活动配置: `signSettingTab`,
        优惠中心__签到活动__活动配置__页签查看: `signSettingQueryPerms`,
        优惠中心__签到活动__活动配置__编辑: `signSettingEditPerms`,
        优惠中心__签到活动__领取记录: `signLogTab`,
        优惠中心__签到活动__领取记录__页签查看: `signLogQueryPerms`,
        优惠中心__签到活动__领取记录__导出: `signLogExportPerms`,
        优惠中心__盈利排行榜: `profitRanking`,
        优惠中心__盈利排行榜__活动配置: `profitRankingSettingTab`,
        优惠中心__盈利排行榜__活动配置__页签查看: `profitRankSettingQueryPerms`,
        优惠中心__盈利排行榜__活动配置__编辑: `profitRankingEditPerms`,
        优惠中心__盈利排行榜__盈利榜开关: `profitRankingStatusPerms`,
        优惠中心__盈利排行榜__机器人: `rankingRobotTab`,
        优惠中心__盈利排行榜__机器人__页签查看: `rankingRobotQueryPerms`,
        优惠中心__盈利排行榜__机器人__新增机器人: `rankingRobotAddPerms`,
        优惠中心__盈利排行榜__机器人__编辑: `rankingRobotEditPerms`,
        优惠中心__盈利排行榜__机器人__删除: `rankingRobotDelPerms`,
        优惠中心__盈利排行榜__机器人__状态开关: `rankingRobotStatusPerms`,
        优惠中心__盈利排行榜__排行榜: `profitRankingTab`,
        优惠中心__盈利排行榜__排行榜__页签查看: `profitRankingQueryPerms`,
        优惠中心__盈利排行榜__领取记录: `profitRankingLogTab`,
        优惠中心__盈利排行榜__领取记录__页签查看: `profitRankingLogQueryPerms`,
        优惠中心__盈利排行榜__领取记录__导出: `profitRankingLogExportPerms`,
        优惠中心__返水设置: `cashBackSetting`,
        优惠中心__返水设置__返水设置: `cashBackTab`,
        优惠中心__返水设置__返水设置__页签查看: `cashBackQueryPerms`,
        优惠中心__返水设置__返水设置__新增设置: `cashBackAddPerms`,
        优惠中心__返水设置__返水设置__比例设置: `cashBackEditPerms`,
        优惠中心__返水设置__返水设置__状态开关: `cashBackStatusPerms`,
        优惠中心__返水设置__返水模板: `cashBackTemplateTab`,
        优惠中心__返水设置__返水模板__页签查看: `cashBackTemplateQueryPerms`,
        优惠中心__返水设置__返水模板__新增模板: `cashBackTemplateAddPerms`,
        优惠中心__返水设置__返水模板__编辑: `cashBackTemplateEditPerms`,
        优惠中心__返水设置__返水模板__删除: `cashBackTemplateDelPerms`,
        优惠中心__返水设置__返水记录: `cashBackLog`,
        优惠中心__返水设置__返水记录__页面查看: `cashBackLogQueryPerms`,
        代理管理__代理后台账号: `agentUserQueryPerms`,
        代理管理: `agentManage`,
        代理管理__代理商查询: `agentQuery`,
        代理管理__代理商查询__页面查看: `agentQueryPerms`,
        代理管理__代理商查询__导出: `agentExportPerms`,
        代理管理__会员代理查询: `memberAgentQuery`,
        代理管理__会员代理查询__页面查看: `memberAgentQueryPerms`,
        代理管理__会员代理查询__导出: `memberAgentExportPerms`,
        代理管理__代理配置: `agentSetting`,
        代理管理__代理配置__分类配置: `agentCategoryTab`,
        代理管理__代理配置__分类配置__页签查看: `agentCategoryQueryPerms`,
        代理管理__代理配置__分类配置__绑定编辑_状态开关: `agentCategoryEditPerms`,
        代理管理__代理配置__代理设置: `agentSettingTab`,
        代理管理__代理配置__代理设置__页签查看: `agentSettingQueryPerms`,
        代理管理__代理配置__代理设置__提交: `agentSettingEditPerms`,
        代理管理__佣金记录: `rebateLog`,
        代理管理__佣金记录__页面查看: `rebateLogQueryPerms`,
        财务管理: `financeManage`,
        财务管理__账户调整: `balanceChange`,
        财务管理__账户调整__账户调整: `balanceChangeTab`,
        财务管理__账户调整__账户调整__页签查看: `balanceChangeQueryPerms`,
        财务管理__账户调整__账户调整__提交: `balanceChangeEditPerms`,
        财务管理__账户调整__审核列表: `balanceChangeApproveTab`,
        财务管理__账户调整__审核列表__页签查看: `balanceChangeApproveQueryPerms`,
        财务管理__账户调整__审核列表__锁定: `balanceChangeApproveLockPerms`,
        财务管理__账户调整__审核列表__审核权限: `balanceChangeApproveEditPerms`,
        财务管理__账户调整__历史记录: `balanceChangeLogTab`,
        财务管理__账户调整__历史记录__页签查看: `balanceChangeLogQueryPerms`,
        财务管理__账户调整__历史记录__导出: `balanceChangeLogExportPerms`,
        财务管理__稽核管理: `adjustManage`,
        财务管理__稽核管理__稽核列表: `adjustQueryTab`,
        财务管理__稽核管理__稽核列表__页签查看: `adjustQueryPerms`,
        财务管理__稽核管理__稽核列表__人工清零: `adjustCleanPerms`,
        财务管理__稽核管理__稽核记录: `adjustLogTab`,
        财务管理__稽核管理__稽核记录__页签查看: `adjustLogQueryPerms`,
        财务管理__稽核管理__流水稽核设置: `adjustSettingTab`,
        财务管理__稽核管理__流水稽核设置__页签查看: `adjustSettingPerms`,
        财务管理__稽核管理__流水稽核设置__保存: `adjustSettingEditPerms`,
        财务管理__出入款审核: `financeApprove`,
        财务管理__出入款审核__线上入款审核: `onlineFinanceApproveTab`,
        财务管理__出入款审核__线上入款审核__页签查看: `onlineFinanceApproveQueryPerms`,
        财务管理__出入款审核__线上入款审核__导出: `onlineFinanceApproveExportPerms`,
        财务管理__出入款审核__线上入款审核__审核权限: `onlineFinanceApprovePerms`,
        财务管理__出入款审核__线下入款审核: `offlineFinanceApproveTab`,
        财务管理__出入款审核__线下入款审核__页签查看: `offlineFinanceApproveQueryPerms`,
        财务管理__出入款审核__线下入款审核__导出: `offlineFinanceApproveExportPerms`,
        财务管理__出入款审核__线下入款审核__锁定: `offlineFinanceApproveLockPerms`,
        财务管理__出入款审核__出款审核: `withdrawApproveTab`,
        财务管理__出入款审核__出款审核__页签查看: `withdrawApproveQueryPerms`,
        财务管理__出入款审核__出款审核__锁定: `withdrawApproveLockPerms`,
        财务管理__出入款审核__出款审核__导出: `withdrawApproveExportPerms`,
        财务管理__出入款审核__出款审核__审核权限: `withdrawApprovePerms`,
        财务管理__出款设置: `withdrawSetting`,
        财务管理__出款设置__页面查看: `withdrawSettingQueryPerms`,
        财务管理__出款设置__新增出款设置模板: `withdrawTemplateAddPerms`,
        财务管理__出款设置__编辑: `withdrawTemplateEditPerms`,
        财务管理__出款设置__删除: `withdrawTemplateDelPerms`,
        财务管理__出款设置__状态开关: `withdrawTemplateStatusPerms`,
        财务管理__提现信息配置: `withdrawConfigSetting`,
        财务管理__提现信息配置__虚拟币信息配置: `virtualCurrencySetting`,
        财务管理__提现信息配置__虚拟币信息配置__页签查看: `virtualSettingQueryPerms`,
        财务管理__提现信息配置__虚拟币信息配置__新增虚拟钱包: `virtualSettingAddPerms`,
        财务管理__提现信息配置__虚拟币信息配置__编辑: `virtualSettingEditPerms`,
        财务管理__提现信息配置__虚拟币信息配置__状态开关: `virtualSettingStatusPerms`,
        财务管理__提现信息配置__虚拟币信息配置__批量删除: `virtualSettingDelPerms`,
        财务管理__提现信息配置__银行卡信息配置: `bankCardSetting`,
        财务管理__提现信息配置__银行卡信息配置__页签查看: `bankCardSettingQueryPerms`,
        财务管理__提现信息配置__银行卡信息配置__新增银行信息: `bankCardSettingAddPerms`,
        财务管理__提现信息配置__银行卡信息配置__编辑: `bankCardSettingEditPerms`,
        财务管理__提现信息配置__银行卡信息配置__状态开关: `bankCardSettingStatusPerms`,
        财务管理__提现信息配置__银行卡信息配置__批量删除: `bankCardSettingDelPerms`,

        财务管理__活动彩金审核__页面查看: `bonusAuditLogQueryPerms`,
        财务管理__活动彩金审核__编辑数据: `bonusAuditLogEditPerms`,
        财务管理__活动彩金审核__导出数据: `bonusAuditLogExportPerms`,

        支付管理: `paymentManage`,
        支付配置: `paymentSetting`,

        风控管理: `riskManage`,
        风控管理__黑名单: `blackListSetting`,
        风控管理__黑名单__IP地址: `blackIpTab`,
        风控管理__黑名单__IP地址__页签查看: `blackIpQueryPerms`,
        风控管理__黑名单__IP地址__添加IP地址: `blackIpAddPerms`,
        风控管理__黑名单__IP地址__编辑: `blackIpEditPerms`,
        风控管理__黑名单__IP地址__删除: `blackIpDelPerms`,
        风控管理__黑名单__银行卡: `blackBankTab`,
        风控管理__黑名单__银行卡__页签查看: `blackBankQueryPerms`,
        风控管理__黑名单__银行卡__添加银行卡: `blackBankAddPerms`,
        风控管理__黑名单__银行卡__编辑: `blackBankEditPerms`,
        风控管理__黑名单__银行卡__删除: `blackBankDelPerms`,
        风控管理__黑名单__数字钱包: `blackWalletTab`,
        风控管理__黑名单__数字钱包__页签查看: `blackWalletQueryPerms`,
        风控管理__黑名单__数字钱包__添加钱包地址: `blackWalletAddPerms`,
        风控管理__黑名单__数字钱包__编辑: `blackWalletEditPerms`,
        风控管理__黑名单__数字钱包__删除: `blackWalletDelPerms`,
        风控管理__黑名单__返水: `blackAgentTab`,
        风控管理__黑名单__返水__页签查看: `blackAgentQueryPerms`,
        风控管理__黑名单__返水__添加会员账号: `blackAgentAddPerms`,
        风控管理__黑名单__返水__编辑: `blackAgentEditPerms`,
        风控管理__黑名单__返水__删除: `blackAgentDelPerms`,
        报表统计: `statisticalReports`,
        报表统计__会员报表: `memberReports`,
        报表统计__会员报表__页面查看: `memberReportsQueryPerms`,
        报表统计__留存统计: `memberRetention`,
        报表统计__留存统计__新增留存: `registerRetentionTab`,
        报表统计__留存统计__新增留存__页面查看: `registerRetentionQueryPerms`,
        报表统计__留存统计__新增留存__导出: `registerRetentionExportPerms`,
        报表统计__留存统计__充值留存: `rechargeRetentionTab`,
        报表统计__留存统计__充值留存__页面查看: `rechargeRetentionQueryPerms`,
        报表统计__留存统计__充值留存__导出: `rechargeRetentionExportPerms`,
        报表统计__留存统计__投注留存: `betRetentionTab`,
        报表统计__留存统计__投注留存__页面查看: `betRetentionQueryPerms`,
        报表统计__留存统计__投注留存__导出: `betRetentionExportPerms`,
        报表统计__资金报表: `financeReports`,
        报表统计__资金报表__页面查看: `financeReportsQueryPerms`,
        报表统计__资金报表__导出: `financeReportsExportPerms`,
        报表统计__注册来源: `registerDomain`,
        报表统计__注册来源__页面查看: `registerDomainQueryPerms`,
        报表统计__注册来源__导出: `registerDomainExportPerms`,
        报表统计__游戏报表: `gameReport`,
        报表统计__游戏报表__类型统计: `gameCategoryReportTab`,
        报表统计__游戏报表__类型统计__页面查看: `gameCategoryReportQueryPerms`,
        报表统计__游戏报表__类型统计__导出: `gameCategoryReportExportPerms`,
        报表统计__游戏报表__场馆统计: `gamePlatformReportTab`,
        报表统计__游戏报表__场馆统计__页面查看: `gamePlatformReportQueryPerms`,
        报表统计__游戏报表__场馆统计__导出: `gamePlatformReportExportPerms`,
        报表统计__游戏报表__子游戏统计: `gameDetailListReportTab`,
        报表统计__游戏报表__子游戏统计__页面查看: `gameDetailListReportQueryPerms`,
        报表统计__游戏报表__子游戏统计__导出: `gameDetailListReportExportPerms`,
        报表统计__游戏报表__子游戏统计__游戏详情查看: `gameDetailListReportQueryPerms`,
        报表统计__游戏报表__子游戏统计__游戏详情导出: `gameDetailListReportExportPerms`,
        报表统计__日运营报表: `operationReport`,
        报表统计__日运营报表__页面查看: `operationReportQueryPerms`,
        报表统计__日运营报表__导出: `operationReportExportPerms`,
        系统管理: `systemManage`,
        // 系统管理__导出任务: `downloadTask`,
        系统管理__导出任务__页签查看: `downloadTaskQueryPerms`,
        系统管理__导出任务__下载: `downloadTaskDownloadPerms`,
        系统管理__导出任务__删除: `downloadTaskDeletePerms`,
        系统管理__管理员: `userSetting`,
        系统管理__管理员__管理员账号: `userAccountTab`,
        系统管理__管理员__管理员账号__页签查看: `userAccountQueryPerms`,
        系统管理__管理员__管理员账号__状态开关: `userAccountStatusPerms`,
        系统管理__管理员__管理员账号__新增管理员: `userAccountAddPerms`,
        系统管理__管理员__管理员账号__编辑: `userAccountEditPerms`,
        系统管理__管理员__管理员账号__修改密码: `userAccountPasswordPerms`,
        系统管理__管理员__管理员账号__批量删除: `userAccountDelPerms`,
        系统管理__管理员____角色管理: `userRoleTab`,
        系统管理__管理员____角色管理__页签查看: `userRoleQueryPerms`,
        系统管理__管理员____角色管理__新增角色: `userRoleAddPerms`,
        系统管理__管理员____角色管理__状态开关: `userRoleStatusPerms`,
        系统管理__管理员____角色管理__编辑: `userRoleEditPerms`,
        系统管理__系统设置: `systemSetting`,
        系统管理__系统设置__域名配置: `domainTab`,
        系统管理__系统设置__域名配置__页面查看: `domainQueryPerms`,
        系统管理__系统设置__域名配置__保存: `domainEditPerms`,
        系统管理__系统设置__系统维护: `maintenanceTab`,
        系统管理__系统设置__系统维护__页面查看: `maintenanceQueryPerms`,
        系统管理__系统设置__系统维护__编辑: `maintenanceEditPerms`,
        系统管理__系统设置__客服密钥管理: `customerServiceTab`,
        系统管理__系统设置__客服密钥管理__页面查看: `customerSecretQueryPerms`,
        系统管理__系统设置__客服密钥管理__编辑: `customerServiceEditPerms`,
        系统管理__日志管理: `systemLog`,
        系统管理__日志管理__管理员登录日志: `adminLoginLogTab`,
        系统管理__日志管理__管理员登录日志__页面查看: `adminLoginLogQueryPerms`,
        系统管理__日志管理__管理员登录日志__导出: `adminLoginLogExportPerms`,
        系统管理__日志管理__管理员操作日志: `adminOperateLogTab`,
        系统管理__日志管理__管理员操作日志__页面查看: `adminOperateLogQueryPerms`,
        系统管理__日志管理__管理员操作日志__导出: `adminOperateLogExportPerms`,

        商户后台__集团管理__组织管理: `organizeManage`,
        商户后台__集团管理__组织管理__公司管理__页面查看: `organizeCompanyQueryPerm`,
        商户后台__集团管理__组织管理__公司管理__公司编辑: `organizeCompanyQueryPerm`,
        商户后台__集团管理__组织管理__公司管理__冻结: `organizeCompanyFrozenPerm`,
        商户后台__集团管理__组织管理__公司管理__费率配置: `organizeCompanyRatePerm`,
        商户后台__集团管理__组织管理__公司管理__重置登录密码: `organizeCompanyResetPerm`,
        商户后台__集团管理__组织管理__站点管理__页面查看: `organizeMerchantQueryPerm`,
        商户后台__集团管理__组织管理__站点管理__新增修改站点: `organizeMerchantEditPerm`,
        商户后台__集团管理__组织管理__站点管理__冻结_维护: `organizeMerchantFrozenPerm`,
        商户后台__集团管理__组织管理__站点管理__费率配置: `organizeMerchantRatePerm`,
        商户后台__集团管理__组织管理__系统公告__页面查看: `organizeNotifyQueryPerm`,
        商户后台__集团管理__组织管理__系统公告__公告编辑: `organizeNotifyEditPerm`,
        商户后台__集团管理__组织管理__版本维护__页面查看: `organizeVersionQueryPerm`,
        商户后台__集团管理__组织管理__版本维护__离线包更新: `organizeVersionOfflinePerm`,
        商户后台__集团管理__组织管理__版本维护__APP包上传: `organizeVersionUploadPerm`,
        商户后台__集团管理__组织管理__站点资源: `organizeResourcePage`,
        商户后台__集团管理__组织管理__站点资源__页面查看: `organizeResourceQueryPerm`,
        商户后台__集团管理__组织管理__站点资源__资源编辑: `organizeResourceEditPerm`,
        // 商户后台__集团管理__组织管理__主题模板管理: `organizeResourceQueryPerm`,
        // 商户后台__集团管理__组织管理__主题模板管理__批量删除: `organizeResourceEditPerm`,
        // 商户后台__集团管理__组织管理__主题模板管理__新增站点: `organizeResourceEditPerm`,
        商户后台__集团管理__支付平台管理__三方平台: `organizePaymentPage`,
        商户后台__集团管理__支付平台管理__三方支付平台__页面查看: `organizePaymentQueryPerm`,
        商户后台__集团管理__支付平台管理__三方支付平台__修改平台: `organizePaymentEditPerm`,
        商户后台__集团管理__游戏管理__游戏管理: `organizeGame`,
        商户后台__集团管理__游戏管理__游戏管理__平台管理__页面查看: `organizeGameQueryPerm`,
        商户后台__集团管理__游戏管理__游戏管理__平台管理__修改平台: `organizeGameEditPerm`,
        商户后台__集团管理__游戏管理__游戏管理__平台管理__维护_启用平台: `organizeGameFrozenPerm`,
        商户后台__集团管理__游戏管理__游戏管理__子游戏管理__页面查看: `organizeSubGameQueryPerm`,
        商户后台__集团管理__游戏管理__游戏管理__子游戏管理__新增游戏: `organizeSubGameEditPerm`,
        商户后台__集团管理__游戏管理__游戏管理__子游戏管理__维护_启用游戏: `organizeSubGameStatusPerm`,
        商户后台__集团管理__游戏管理__游戏管理__子游戏管理__开启_关闭游戏: `organizeSubGameFrozenPerm`,
        商户后台__集团管理__游戏管理__游戏管理__类型管理__页面查看: `organizeGameTypeQueryPerm`,
        商户后台__集团管理__游戏管理__游戏管理__类型管理__类型编辑: `organizeGameTypeEditPerm`,
        商户后台__集团管理__注单管理: `organizeOrder`,
        商户后台__集团管理__注单管理__人工拉单__页面查看: `organizeManualOrderQueryPerm`,
        商户后台__集团管理__注单管理__人工拉单__人工拉取注单: `organizeManualOrderAddPerm`,
        商户后台__集团管理__注单管理__人工拉单__人工拉单取消: `organizeManualOrderCancelPerm`,
        商户后台__集团管理__报表统计: `organizeReport`,
        商户后台__集团管理__报表统计__盈亏报表__页面查看: `organizeReportQueryPerm`,
        商户后台__集团管理__报表统计__盈亏报表__数据导出: `organizeReportExportPerm`,
        商户后台__集团管理__报表统计__公司报表__页面查看: `organizeCompanyReportQueryPerm`,
        商户后台__集团管理__报表统计__公司报表__数据导出: `organizeCompanyReportExportPerm`,
        商户后台__集团管理__系统管理: `organizeSystem`,
        商户后台__集团管理__系统管理__账号权限__后台账号__页面查看: `organizeSystemManageQueryPerm`,
        商户后台__集团管理__系统管理__账号权限__后台账号__新增账号: `organizeSystemManageEditPerm`,
        商户后台__集团管理__系统管理__账号权限__后台账号__冻结账号: `organizeSystemManageFrozenPerm`,
        商户后台__集团管理__系统管理__账号权限__权限角色__页面查看: `organizeSystemRoleQueryPerm`,
        商户后台__集团管理__系统管理__账号权限__权限角色__新增角色: `organizeSystemRoleEditPerm`,
        商户后台__集团管理__系统管理__IP白名单__页面查看: `organizeWhiteIpQueryPerm`,
        商户后台__集团管理__系统管理__IP白名单__编辑白名单: `organizeWhiteIpEditPerm`,
        商户后台__集团管理__系统管理__后台日志: `organizeLogPage`,
        商户后台__集团管理__系统管理__后台日志__操作日志页面查看: `organizeManageLogQueryPerm`,
        商户后台__集团管理__系统管理__后台日志__登录日志页面查看: `organizeLoginLogQueryPerm`,
        商户后台__集团管理__支付管理: 'organizePaymentManage',
        商户后台__集团管理__支付管理__支付类型: 'organizePayTypePage',
        商户后台__集团管理__支付管理__提现账号管理: 'organizeWithdrawalPage',
        商户后台__集团管理__支付管理__提现账号管理__新增: 'organizeBankCardAddPerm',
        商户后台__集团管理__支付管理__提现账号管理__编辑: 'organizeBankCardEditPerm',
        商户后台__集团管理__支付管理__提现账号管理__删除: 'organizeBankCardDeletePerm',
        商户后台__集团管理__支付管理__提现账号管理__银行卡: 'organizeBankCardTab',
        商户后台__集团管理__支付管理__提现账号管理__银行卡__新增: 'organizeBankCardAddPerm',
        商户后台__集团管理__支付管理__提现账号管理__银行卡__编辑: 'organizeBankCardEditPerm',
        商户后台__集团管理__支付管理__提现账号管理__银行卡__删除: 'organizeBankCardDeletePerm',
        商户后台__集团管理__三方对账: 'organizeGameReconcile',
        商户后台__集团管理__三方对账__三方对账: 'organizeGameReconcilePage',
        商户后台__集团管理__三方对账__三方对账__数据导出: 'organizeGameReconcileExportPerm',

        商户后台__公司管理__公司管理: `companyManage`,
        商户后台__公司管理__公司管理__站点管理: `companyMerchantPage`,
        商户后台__公司管理__公司管理__站点管理__页面查看: `companyMerchantQueryPerm`,
        商户后台__公司管理__公司管理__站点管理__新增站点: `companyMerchantEditPerm`,
        商户后台__公司管理__公司管理__站点管理__维护_取消维护: `companyMerchantFrozenPerm`,
        商户后台__公司管理__公司管理__站点管理__费率配置: `companyMerchantRatePerm`,
        商户后台__公司管理__公司管理__系统公告: `companyNotifyPage`,
        商户后台__公司管理__公司管理__系统公告__页面查看: `companyNotifyQueryPerm`,
        商户后台__公司管理__公司管理__系统公告__公告编辑: `companyNotifyEditPerm`,
        商户后台__公司管理__报表统计: `companyReport`,
        商户后台__公司管理__报表统计__盈亏报表: `companyProfitReportPage`,
        商户后台__公司管理__报表统计__盈亏报表__页面查看: `companyProfitReportQueryPerm`,
        商户后台__公司管理__报表统计__盈亏报表__数据导出: `companyProfitReportExportPerm`,
        商户后台__公司管理__系统管理: `companySystem`,
        商户后台__公司管理__系统管理__账号权限: `companyRolePage`,
        商户后台__公司管理__系统管理__账号权限__后台账号: `companyManageRoleTab`,
        商户后台__公司管理__系统管理__账号权限__后台账号__页面查看: `companyManageRoleQueryPerm`,
        商户后台__公司管理__系统管理__账号权限__后台账号__新增账号: `companyManageRoleEditPerm`,
        商户后台__公司管理__系统管理__账号权限__后台账号__冻结账号: `companyManageRoleFrozenPerm`,
        商户后台__公司管理__系统管理__账号权限__权限角色: `companyRolePermTab`,
        商户后台__公司管理__系统管理__账号权限__权限角色__页面查看: `companyRolePermQueryPerm`,
        商户后台__公司管理__系统管理__账号权限__权限角色__新增角色: `companyRolePermEditPerm`,
        商户后台__公司管理__系统管理__IP白名单: `companyWhiteIpPage`,
        商户后台__公司管理__系统管理__IP白名单__页面查看: `companyWhiteIpQueryPerm`,
        商户后台__公司管理__系统管理__IP白名单__新增白名单: `companyWhiteIpEditPerm`,
        商户后台__公司管理__系统管理__后台日志: `companyLogPage`,
        商户后台__公司管理__系统管理__后台日志__操作日志页面查看: `companyManageLogQueryPerm`,
        商户后台__公司管理__系统管理__后台日志__登录日志页面查看: `companyLoginLogQueryPerm`,
    },
}

export default globalEnumPermData
