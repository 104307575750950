const GamePlatformArr = [
    { value: "AG", label: "AG" },
    { value: "BG", label: "BG" },
    { value: "MG", label: "MG" },
]

const loginType = [
    {
        value: "test",
        label: "test",
    },
    {
        value: "test1",
        label: "test1",
    },
]

export const userLevel = [
    {
        label: "代理商",
        value: "代理商",
    },
    {
        label: "普通会员",
        value: "普通会员",
    },
]

export const userVipLevel = [
    {
        label: "VIP1",
        value: "VIP1",
    },
    {
        label: "VIP2",
        value: "VIP2",
    },
    {
        label: "VIP3",
        value: "VIP3",
    },
    {
        label: "VIP4",
        value: "VIP4",
    },
]

export const userStatus = [
    {
        label: "正常",
        value: "正常",
    },
    {
        label: "冻结",
        value: "冻结",
    },
]


const data = {
    GamePlatformArr,
    loginType,
    userLevel,
    userVipLevel,
    userStatus,
}

export default data
