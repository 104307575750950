import mergeWith from "lodash/mergeWith"
import { get } from "./tools"
import { IDomRect, IRouter, MenuMap } from "./types"
import { baseRouters } from "@/Router/router"
import Api from "@/server"
import { workStore } from "@/store"

export * from "./requestAnimationFrame"
export * from "./time"

export const log = (key: string, ...args: any[]): void => {
    const s = `\n${key} \n`
    console.log("__custom \n\n", ...args, s)
}

/**
 * 对象转url查询参数字符串
 *
 * @param {Record<string, any>} obj
 * @return {*}
 */
export const objToQuery = (obj: Record<string, any>) => {
    const result: string[] = []
    Object.entries(obj).forEach((entries) => {
        const [key, value] = entries
        const value_ = value as string
        result.push(`${key}=${encodeURIComponent(value_)}`)
    })
    return result.join("&")
}

/**
 * lodash 的 mergeRight 改造，将会选择为null，undefined的值
 *
 * @returns
 */
export const mergePro = <T>(object: T, ...otherArgs: (T | null | undefined)[]): T => {
    return mergeWith(object, ...otherArgs, (obj: T, source: T) => {
        if ([obj, source].some((item) => item == null)) {
            return obj || source
        } else if ([obj, source].every((item) => item instanceof Array)) {
            const objLen = get(obj, "length", 0)
            const sourceLen = get(source, "length", 0)
            if (objLen !== sourceLen) {
                return source
            }
        }
    })
}

/**
 * 生成随机数
 *
 * @param {number} [n=32]
 * @returns
 */
export const random = (n = 32): string => {
    const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"
    const letter = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz"
    const maxPos = chars.length
    let result = ""
    for (let i = 0; i < n; i++) {
        result += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    result = letter.charAt(Math.floor(Math.random() * letter.length)) + result
    return result.substring(0, result.length - 1)
}

/**
 * 生成序列数组
 *
 * @static
 * @param {number} n
 * @returns
 *
 */
export const range = (n: number): Array<number> => {
    return Array.from(new Array(n).keys())
}

/**
 * 动态加载js
 *
 * @param {string} src
 * @return {*}  {Promise<void>}
 */
export const loadJs = (src: string, id?: string): Promise<void> => {
    return new Promise((resolve) => {
        if (id) {
            const script = document.getElementById(id)
            if (script) {
                resolve()
                return
            }
        }
        const script = document.createElement("script")
        script.src = src
        script.async = true
        if (id) {
            script.id = id
        }
        document.body.appendChild(script)
        script.onload = () => {
            resolve()
        }
    })
}

/**
 * 获得非空值
 *
 * @param {...any[]} args
 * @return {*}
 */
export const notEmpty = <T>(...args: any[]): T => {
    return [...args].find((arg) => arg != null)
}

/**
 * 限制value不能超出最大最小值
 *
 * @param {number} min
 * @param {number} max
 * @param {number} value
 * @return {*}
 */
export const valueInterval = (min: number, max: number, value: number) => {
    return Math.min(max, Math.max(min, value))
}

export const getImgInfo = (src: string): Promise<{ baseWidth: number; baseHeight: number }> => {
    return new Promise((resolve) => {
        const img = new Image()
        img.src = src
        img.style.visibility = "hidden"
        img.style.position = "fixed"
        document.body.appendChild(img)
        img.onload = () => {
            setTimeout(() => document.body.removeChild(img), 1000)
            resolve({ baseWidth: img.offsetWidth, baseHeight: img.offsetHeight })
        }
    })
}

export const animationScrollTo = (
    dom: HTMLDivElement,
    type: "left" | "top",
    info: { targetValue?: number; dx?: number; movementTime?: number; speed?: number },
) => {
    const speed = info.speed || 10
    const movementTime = info.movementTime || 120
    let currentScrollValue = 0
    if (type === "left") {
        currentScrollValue = dom.scrollLeft
    } else {
        currentScrollValue = dom.scrollTop
    }
    const dx = info.dx || 0
    const scrollEndValue = info.targetValue || currentScrollValue + dx
    const moveScroll = (targetValue: number, time: number) => {
        dom.scrollTo({ [type]: targetValue })
        setTimeout(() => {
            const currentVal = type === "top" ? dom.scrollTop : dom.scrollLeft
            if (Math.abs(currentVal - scrollEndValue) > 10) {
                moveScroll(targetValue + speed, time)
            }
        }, time)
    }
    const unitTime = (scrollEndValue - currentScrollValue) / speed
    moveScroll(currentScrollValue + speed, movementTime / unitTime)
}

/**
 * 替换string的replaceAll
 *
 * @param {string} base
 * @param {string} str
 * @param {string} newStr
 * @return {*}
 */
export const replaceAll = (base: string, str: string, newStr: string) => {
    let result = base
    while (base.includes(str)) {
        result = base.replace(str, newStr)
    }
    return result
}

/**
 * 浏览器视口的高度
 *
 * @return {*}
 */
export const getWindowHeight = () => {
    let windowHeight = 0
    if (document.compatMode === "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight
    } else {
        windowHeight = document.body.clientHeight
    }
    return windowHeight
}

/**
 * 浏览器视口的宽度
 *
 * @return {*}
 */
export const getWindowWidth = () => {
    let width = 0
    if (document.compatMode === "CSS1Compat") {
        width = document.documentElement.clientWidth
    } else {
        width = document.body.clientWidth
    }
    return width
}

/**
 * dom 元素的 resize 监听对象
 *
 * @param {(Element | HTMLElement)} element
 * @param {string[]} attributeFilter
 * @param {() => void} callback
 * @return {*}
 */
export const mutationObserver = (
    element: Element | HTMLElement,
    attributeFilter: string[],
    callback: () => void,
) => {
    const keys = ["MutationObserver", "WebKitMutationObserver", "MozMutationObserver"]
    const Observer = get<Window, any>(window, keys, null)
    if (Observer != null) {
        const observer: MutationObserver = new Observer(callback)
        observer.observe(element, {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
            attributeFilter: attributeFilter.concat("style") || undefined,
        })
        return observer
    }
    return null
}

/**
 * document.querySelector 的简写
 *
 * @param {string} selector
 * @return {*}
 */
export const q = (selector: string) => {
    return document.querySelector(selector)
}

/**
 * DomRect赋值到对象
 *
 * @param {HTMLDivElement} target
 * @return {*}
 */
export const getRect = (target: HTMLDivElement | string, scale = 1): IDomRect => {
    const dom = typeof target === "string" ? (q(target) as HTMLDivElement) : target
    const targetRect = dom.getBoundingClientRect()
    const centenX = targetRect.left + targetRect.width / 2
    const centenY = targetRect.top + targetRect.height / 2
    const center = {
        x: centenX * scale,
        y: centenY * scale,
        left: centenX * scale,
        right: centenX * scale,
        top: centenY * scale,
        bottom: centenY * scale,
    }
    return {
        x: targetRect.x * scale,
        y: targetRect.y * scale,
        top: targetRect.top * scale,
        left: targetRect.left * scale,
        width: targetRect.width * scale,
        right: targetRect.right * scale,
        height: targetRect.height * scale,
        bottom: targetRect.bottom * scale,
        center,
    }
}

/**
 * 解析菜单树
 *
 * @return {*}
 */
export const createMenuMap = (baseRouters: Array<IRouter>) => {
    const routers = baseRouters.filter((r) => r.key !== "home") as any

    let menuMap: MenuMap = {}
    for (let i of routers) {
        menuMap[i.key] = {
            self: i,
        }
        if (i.children) {
            for (let j of i.children) {
                menuMap[j.key] = {
                    self: j,
                    parent: i,
                }
            }
        }
    }
    return { routers, menuMap } as { routers: IRouter[]; menuMap: MenuMap }
}

type EnumSingleData = Record<string | number, any>
type EnumData = Record<string, EnumSingleData>
/**
 * 刷新动态枚举值
 *
 */
export const refreshGlobalDynamicEnum = async () => {
    const result: EnumData = await Api.post("/systemSetting/allDynamicEnums", {})
    const globalEnum = {} as Record<string, any>
    if (result) {
        for (let [key, value] of Object.entries(result)) {
            const arr = []
            // TODO 临时去除
            if (!value) {
                continue
            }
            for (let [k, v] of Object.entries(value)) {
                if (v instanceof Object) {
                    const arrInside = []
                    for (let [k1, v1] of Object.entries(v)) {
                        arrInside.push({
                            label: v1,
                            value: k1,
                        })
                    }
                    globalEnum[`${key}_${k}`] = arrInside
                    result[`${key}_${k}`] = v
                } else {
                    arr.push({
                        label: v,
                        value: k,
                    })
                }
            }
            globalEnum[key] = arr
        }

        workStore.setGlobalEnum({
            ...workStore.globalEnum,
            ...globalEnum,
        })

        workStore.setGlobalEnumKV({
            ...workStore.globalEnumKV,
            ...result,
        })
    }
}
/**
 * 刷新固定枚举值
 *
 */
export const refreshGlobalEnum = async () => {
    const result: EnumData = await Api.post("/systemSetting/allEnums", {})
    const globalEnum = {} as Record<string, any>
    if (result) {
        // 修改后端枚举
        result.siteLayoutEnum = {
            H1: "横版",
            V1: "竖版1",
            V2: "竖版2",
        }
        for (let [key, value] of Object.entries(result)) {
            const arr = []
            // TODO 临时去除
            if (!value) {
                continue
            }
            for (let [k, v] of Object.entries(value)) {
                arr.push({
                    label: v,
                    value: k,
                })
            }
            globalEnum[key] = arr
        }
        workStore.setGlobalEnum({
            ...workStore.globalEnum,
            ...globalEnum,
        })
        workStore.setGlobalEnumKV({
            ...workStore.globalEnumKV,
            ...result,
        })
    }
}
/**
 * 刷新所有枚举值
 *
 */
export const refreshAllEnum = async () => {
    await refreshGlobalEnum()
    await refreshGlobalDynamicEnum()
}

const refreshEnumFromApi = async (
    url: string,
    enumKey: string,
    labelKey: string,
    valueKey: string,
) => {
    const result: EnumData = await Api.post(url, {
        pageNo: 1,
        pageSize: 9999,
    })
    const data = get(result, "data", [])
    const globalEnum = {} as Record<string, any>
    const globalEnumKV = {} as Record<string, any>
    if (data) {
        const arr = []
        const obj = {}
        for (let [_, rowData] of Object.entries(data)) {
            const label = rowData[labelKey]
            const value = rowData[valueKey]
            arr.push({
                label: label,
                value: value,
            })
            obj[value] = label
        }
        globalEnum[enumKey] = arr
        globalEnumKV[enumKey] = obj
        workStore.setGlobalEnum({
            ...workStore.globalEnum,
            ...globalEnum,
        })
        workStore.setGlobalEnumKV({
            ...workStore.globalEnumKV,
            ...globalEnumKV,
        })
    }
}
/**
 * 刷新特殊枚举值
 *
 */
export const refreshGlobalSpecialEnum = async () => {
    // await refreshEnumFromApi(
    //     "/paymentConfig/paymentPlatforms",
    //     "payPlatforms",
    //     "platformName",
    //     "platformCode",
    // )
    // await refreshEnumFromApi(
    //     "/paymentConfig/paymentTypes",
    //     "payTypes",
    //     "payTypeName",
    //     "payTypeCode",
    // )
}

export const killConsole = () => {
    window.console.log = () => {}
    window.console.info = () => {}
    window.console.warn = () => {}
    window.console.error = () => {}
    console.clear()
}

export const isPromiseFunc = (func: any) => {
    if (get(func, "constructor.name", null) === "AsyncFunction" || func instanceof Promise) {
        return true
    }
    return false
}
