import { random } from "."

/**
 * 通过定义一个任务队列，实现请求并发控制，并且可以多处调用
 *
 * @class PromiseQueue
 */
class PromiseQueue {
    limit = 0
    running = 0
    eventMap: Record<string, (val: any) => void> = {}
    queue: Array<{ task: () => Promise<any>; id: string }>
    isOnlyOne: boolean = false
    onlyRes: any = null
    constructor(limit: number, isOnlyOne?: boolean) {
        this.limit = limit || 3
        this.running = 0
        this.queue = []
        this.eventMap = {}
        this.isOnlyOne = isOnlyOne || false
    }

    addListen = (id: string, cbk: any) => {
        this.eventMap[id] = cbk
    }

    emit = (id: string, value: any) => {
        const cbk = this.eventMap[id]
        if (cbk) {
            cbk(value)
        }
    }

    removeListen = (id: string) => {
        delete this.eventMap[id]
    }

    createPool = (task: () => Promise<any>, taskId?: string) => {
        if (!(typeof task === "function")) {
            throw new Error("task 必需为一个函数")
        }
        const id = taskId || `${random()}`
        this.queue.push({ task, id })
        this.next()
        return new Promise((resolve) => {
            this.addListen(id, (value: any) => {
                this.removeListen(id)
                resolve(value)
            })
        })
    }

    createPools = async (tasks: Array<() => Promise<any>>) => {
        for (let index = 0; index < tasks.length; index++) {
            const task = tasks[index]
            await this.createPool(task)
        }
    }

    next = () => {
        if (this.running < this.limit && this.queue.length !== 0) {
            const taskItem = this.queue.shift() as {
                task: () => Promise<any>
                id: string
            }
            const { task, id } = taskItem
            this.running++
            const k = window.setTimeout(() => {
                this.emit(id, null)
                this.next()
            }, 15000)
            if (this.isOnlyOne && this.onlyRes != null) {
                Promise.resolve().finally(() => {
                    // 奇妙的时序问题，需要加上Promise.resolve()，要不然导致 emit 比监听先执行
                    this.emit(id, this.onlyRes)
                    clearTimeout(k)
                    this.running--
                    this.next()
                })
                return
            } else {
                task()
                    .then((res) => {
                        this.onlyRes = res
                        this.emit(id, res)
                    })
                    .finally(() => {
                        clearTimeout(k)
                        this.running--
                        this.next()
                    })
            }
        }
    }
}

export default PromiseQueue
